import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
//import favicon144 from '../../static/favicon144.png';
import favicon32 from '../../static/favicon.png';
//import favicon16 from '../../static/favicon16.png';

const SEO = props => (
    <StaticQuery
    query={detailsQuery}
    render={(data) => {
      const title = props.title || data.site.siteMetadata.title;
      return (
        <Helmet
          htmlAttributes={{
            lang: 'en',
          }}
          title={title}
          titleTemplate={`%s - ${data.site.siteMetadata.title}`}
          link={[
            { rel: 'shortcut icon', type: 'image/png', href: `${favicon32}`, sizes: "32x32" },
          ]}
//          link={[
//            { rel: 'shortcut icon', type: 'image/png', href: `${favicon16}`, sizes: "16x16" },
//          ]}
//          link={[
//            { rel: 'apple-touch-icon', href: `${favicon144}`, sizes: "144x144" },
//         ]}
        />
      );
    }}
  />
);

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
